import { createRouter, createWebHistory } from "vue-router";

import HomePage from "./HomePage.vue";
import NotFound from "./pages/NotFound.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: HomePage,
      meta: {
        title: "Home",
      },
    },
    { path: "/:notFound(.*)", component: NotFound },
  ],
});

router.beforeEach((to) => {
  document.title = to.meta?.title ?? "Default Title";
});

export default router;
