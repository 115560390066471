import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCoffee,
  faChild,
  faCircle,
  faArchive,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faGithub,
  faLinkedin,
  faBlogger,
} from "@fortawesome/free-brands-svg-icons";

import router from "./router.js";

library.add(
  faCoffee,
  faChild,
  faCircle,
  faArchive,
  faEnvelope,
  faTwitter,
  faGithub,
  faLinkedin,
  faBlogger
);

const app = createApp(App);
app.use(ElementPlus);
app.use(router);
app.mount("#app");
